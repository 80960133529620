body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a{
  text-decoration: none !important;
}

a:hover{
  text-decoration: none !important;
}

html {
  font-size: 16px;
}