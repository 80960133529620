.print-bill-container{
    text-align: left;
    border: solid thin lightgrey
}


.print-bill-header{
    text-align: center
}

.print-bill-header-address{
    padding: 10px;
}

.company-name-address{
    line-height: 1.6em
}

.company-name{
    font-weight: bold;
    font-size: 1.6em;
    padding-bottom: 5px
}

.bill-header{
    background-color: lightcyan;
    display: flex;
    flex-direction: row;
}

.bill-body{
    padding: 10px
}

.bill-no{
    flex-basis: 50%;
    padding: 10px
}

.bill-date{
    flex-basis: 50%;
    padding: 10px;
    text-align: right;
}

.bill-table{
    width: 100%;
    border: solid thin lightgrey
}

.bill-table thead tr{
    padding: 10px;
    background-color: lightgrey
}

.bill-table thead tr>td{
    padding: 10px;
}

.bill-table tbody tr>td{
    padding: 10px;
}

.footer-td-gap{
    height: 30vh;
}

.align-right{
    text-align: right
}

.bill-footer{
    padding: 10px
}

.bill-header-customer{
    padding: 10px
}

.text-highlight{
    background-color: red;
    color: white;
    font-size: 0.9em;
    padding: 2px;
    border-radius: 5px;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.3)
}