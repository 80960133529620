.table-display-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.display-grid-div{
    background-color: #FECBD0;
    padding: 5px;
    display: flex;
    flex-direction: row;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.1)
}


.display-grid-div-left{
    text-align: left;
    width: 100%
}

.display-grid-div-right{
    text-align: right;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center
}